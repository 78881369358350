$colorWhite: #fff;
.register-modal{
    width: 100%;
    height: 100%;
    margin:0 auto;
    display:table;
    position: fixed;
    left: 0;
    right:0;
    top: 50%; 
    border:1px solid;
    z-index: 999;
    -webkit-transform:translateY(-50%);
    -moz-transform:translateY(-50%);
    -ms-transform:translateY(-50%);
    -o-transform:translateY(-50%);
    transform:translateY(-50%);
    z-index: 100000;
    .wrapper {
        margin: 20px auto;
        max-width: 420px !important;
        width: 100%;
    }
}
.no-thanks {
    cursor: pointer;
}
.register {
    background: url(https://bayan.s3-us-west-2.amazonaws.com/static-content/register-bg.png.webp) no-repeat;
    background-size: 100%;
    background-position: bottom;
    background-color: #8fadbdc7;
    min-height: calc(100vh - 70px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    overflow-y: auto;
    @media (max-width: 480px) {
        background-color: #234155 !important;
        padding-top: 40px !important;
    }
    .wrapper {
        margin: 20px auto;
        max-width: 420px !important;
        width: 100%;
        @media (max-width: 480px) {
            margin: 0;
            max-width: 100%;
            .box {
                background: url(https://bayan.s3-us-west-2.amazonaws.com/static-content/register-bg.png.webp) no-repeat;
                background-color: #234155;
                background-size: 100%;
                background-position: bottom;
                padding: 30px 20px 60px 20px;
                height: calc(100vh - 70px);
                border-radius: 0 !important;
            }
        }
        .react-tel-input .country-list .country {
            padding: 7px 9px;
            display: flex;
            color: #333;
            align-items: center;
        }
        .box {
            position: relative;
            background-color: #234155;
            padding: 30px 20px;
            border-radius: 8px;
            text-align: center;
            color: #fff;
            width: 100%;
            &.gray-bg {
                background-color: #f2f2f2;
            }
            @media (max-width: 480px) {
                padding: 10px 0px;
            }
            h1 {
                font-size: 14px;
                margin-top: 0;
                margin-bottom: 15px;
            }
            p {
                max-width: 600px;
                margin: auto;
            }
            .request-info-close {
                position: absolute;
                right: -20px;
                top: -20px;
                font-size: 18px;
                cursor: pointer;
            }
            .inner-box {
                max-width: 400px;
                padding: 0 12px;
                margin: auto;
                .react-tel-input {
                    margin-bottom: 20px;
                    .form-control {
                        width: 100%;
                    }
                }
                .select-field {
                    background-color: #fff;
                    border: 0;
                    border-radius: 8px;
                    margin-bottom: 20px;
                    text-align: left;
                    padding-right: 25px;
                    span {
                        padding-left: 10px;
                        font-size: 12px;
                    }
                    .react-dropdown-select-input {
                        // display: none;
                    }
                    .react-dropdown-select-dropdown-handle {
                        position: absolute;
                        right: 10px;
                    }
                    .react-dropdown-select-no-data {
                        color: #013855 !important;
                    }
                }
                .field {
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    margin-bottom: 20px;
                    position: relative;
                    label {
                        margin-bottom: 4px;
                    }
                    input {
                        background: #f2f2f2;
                        border-radius: 5px;
                        padding: 6px 10px 4px 10px;
                        height: 34px;
                        outline: none;
                        border: 0;
                        width: 100%;
                    }
                    input[type="password"] {
                        padding-right: 20px !important;
                    }
                    .eye {
                        background: url('https://bayan.s3-us-west-2.amazonaws.com/static-content/open-eye.png.webp');
                        background-size: 100%;
                        height: 20px;
                        width: 20px;
                        display: inline-block;
                        position: absolute;
                        top: 8px;
                        right: 15px;
                        opacity: 0.5;
                        cursor: pointer;
                    }
                    .fa-eye-slash {
                        background: url('https://bayan.s3-us-west-2.amazonaws.com/static-content/hide.png.webp');
                        background-size: 100%;
                    }
                }
                .field-check {
                    label {
                        font-size: 12px;
                        text-align: left;
                    }
                    display: flex;
                    align-items: center;
                    &.checkbox {
                        padding: 10px 2px 0px 5px;
                    }
                    input {
                        margin-right: 10px;
                    }
                    a {
                        font-weight: 600;
                    }
                }
                .action {
                    display: flex;
                    flex-direction: column;
                    a {
                        padding: 5px;
                    }
                }
                
            }
        }
    }
}

.login-btn {
    background-color: #e1bf42;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px 0 10px 0;
    height: 34px;
    border-radius: 40px;
    cursor: pointer;
}
.login-btn.disabled {
    opacity: 0.8;
    pointer-events: none;
}

.register-steps {
    background-color: #2e5266ed;
    align-items: flex-start;
    text-align: center;
    font-size: 14px;
    color: #fff;
    padding: 20px 0;
    .wrapper {
        max-width: 240px;
    }
    .backbtn {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        .back-arrow {
            margin: 8px 10px;
            height: 8px;
            width: 8px;
            display: inline-block;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            transform: rotate(135deg);
        }
    }
    .i-am {
        margin: 40px 0;
        font-weight: 600;
        font-size: 16px;
    }
    .h-info {
        margin: 15px 0 0 0;
        font-weight: 600;
        font-size: 16px;
    }
    .select-me {
        p {
            margin-bottom: 0px;
        }
    }
    .select-me.dropdown-select {
        position: relative;
        .select-me {
            display: block;
            text-align: center;
            border: 1px solid #fff;
            padding: 6px 25px;
            border-radius: 6px;
            cursor: pointer;
        }
        ul {
            background-color: #fff;
            color: #2F5266;
            width: 100%;
            position: absolute;
            top: 35px;
            margin: 0;
            left: 0;
            right: 0;
            max-width: 100%;
            border-radius: 0 0 6px 6px;
            display: none;
            li {
                padding: 10px 45px;
                &:hover {
                    font-weight: 600;
                }
            }
        }
    }
    .select-me.dropdown-select.open {
        .select-me {
            background-color: #fff;
            border-radius: 6px 6px 0 0;
            color: #2F5266;
            font-weight: 600;
        }
        ul {
            display: block;
        }
    }
    .selected {
        font-weight: 600;
    }
    ul {
        padding: 0;
        margin: 10px auto;
        max-width: 150px;
        li {
            list-style: none;
            text-align: left;
            padding: 4px;
            cursor: pointer;
        }
    }
    .listactive {
        max-width: 280px;
        font-size: 12px;
        li {
            border: 1px solid #ffffff;
            padding: 8px;
            text-align: center;
            margin: 10px 0;
            border-radius: 4px;
        }
        li.active {
            background-color: #fff;
            color: #2e5266ed;
        }
    }
}
.register-steps .wrapper.wrapper-md {
    max-width: 260px;
}
.register-steps .wrapper.wrapper-lg {
    max-width: 350px;
}

.degree-popup-back-link {
    width: 1.5em;
    height: 1.5em;
    max-width: 13px;
    max-height: 13px;
    display: block;
    vertical-align: middle;
    margin-left: auto;
    cursor: pointer;
    position: relative;
    top: 0%;
    left: 50%;
    margin: 0 auto;
    cursor: pointer;
    &:after,
    &:before {
      background: $colorWhite;
      content: "";
      height: 2px;
      max-width: calc(13px * 1.414);
      position: absolute;
      top: 0;
      transition: 0.05s ease-in-out background-color;
      width: calc(1.5em * 1.414);
    }
    &:before {
      left: 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      -webkit-transform-origin: left;
      -ms-transform-origin: left;
      transform-origin: left;
    }
    &:after {
      right: 0;
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
      -webkit-transform-origin: right;
      -ms-transform-origin: right;
      transform-origin: right;
    }
}

.apply-btn {
    width: 199px;
    height: 45px;
    font-size: 16px;
}

.bayan-button {
    margin: 20px 0;
}
