@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
html {
  scroll-behavior: smooth;
}
html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  font-family: Poppins, sans-serif !important;
  color: #234155;
}
button {
  outline: none;
  cursor: pointer;
}
.app-layout {
  padding-top: 74px;
  @media (max-width: 1024px) {
    padding-top: 69px;
    padding-bottom: 83px;
  }
}
a {
  text-decoration: none;
  cursor: pointer;
}
img {
  max-width: 100%;
}
h1, h2, h3, h4 {
  font-family: Poppins, sans-serif !important;
}
.rounded {
  border-radius: 50%;
}
p {
  font-family: Poppins, sans-serif !important;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}
.bayan-button {
  border-radius: 43px;
  border: none;
  width: 199px;
  height: 50px;
  color: #fff;
  background-color: #2E5266;
  opacity: 1;
  display: inline-block;
  text-align: center;
  padding: 11px;
  cursor: pointer;
}
.text-center {
  text-align: center;
}
.headingF {
  font-size: 40px;;
  font-weight: 600;
  color: #2F5266;
  font-family: Poppins, sans-serif;
}
.headingF-sm {
  font-size: 30px;
}
.sub-headingF {
  font-size: 20px;
  color: #2F5266;
  font-family: Poppins, sans-serif;
  margin: 0;;
}
.blue-bg {
  background: #2F5266 0% 0% no-repeat padding-box;
}
.bayan-faculty.blue-bg {
  .container {
    max-width: 1100px;
    .inner-block {
      border-radius: 10px;
      cursor: pointer;
    }
  }
  .slick-list {
    max-width: 960px !important;
    margin: auto;
  }
  .slick-next {
    right: -5px !important;
  }
  .slick-prev {
    left: -5px !important;
  }
  .slick-slide {
    margin: 20px 0;
    .slick-slider .slick-prev {
      left: -5px !important;
      cursor: pointer;
    }
    div {
      display: flex;
      justify-content: center;
      div {
        background-color: #fff;
        margin: 15px;
        img {
          height: 150px;
          object-fit: cover;
          width: 100%;
          background-color: #000;
        }
        h1 {
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0px;
          color: #2E5266;
          text-transform: capitalize;
          margin: 15px 0 0 0;
          padding: 0 15px;
        }
        p {
          margin: 4px 0 0 0;
          letter-spacing: 0px;
          color: #2E5266;
          font-size: 12px;
          padding: 0 15px;
          &:last-child {
            margin-bottom: 15px !important;
          }
        }
      }
    }
  }
}


.slick-slider {
  height: 100%;
  width: 100%;
  position: relative;
  .slick-list {
      width: 100%;
      overflow: hidden;
      .slick-track {
          display: flex;
          height: 100%;
          .slick-slide {

          }
      }
  }
  .slick-prev {
    left: -60px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 40px;
    height: 40px;
    position: absolute;
    z-index: 33;
    background: url("/icons/slick-prev-arrow.svg");
      [dir="rtl"] & {
        background: url("/icons/slick-prev-arrow.svg");
      }
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-position: center;
    border: 0;
    border-radius: 50%;
    background-size: 11px;
    box-shadow: 0px 3px 6px #00000029;
    background-color: #fff;
    outline: none;
    cursor: pointer;
  &.slick-disabled {
    display: none !important;
  }
  [dir="rtl"] & {
    left: -15px;
    right: auto;
  }
}
.slick-next {
  right: -60px;
  width: 40px;
  height: 40px;
  position: absolute;
  z-index: 33;
  background: url("/icons/slick-next-arrow.svg");
  [dir="rtl"] & {
    background: url("/icons/slick-next-arrow.svg");
  }
  background-repeat: no-repeat;
  text-indent: -9999px;
  background-position: center;
  border: 0;
  border-radius: 50%;
  background-size: 11px;
  box-shadow: 0px 3px 6px #00000029;
  background-color: #fff;
  outline: none;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 999;
  cursor: pointer;
  [dir="rtl"] & {
    left: -15px;
    right: auto;
  }
  &.slick-disabled {
    display: none !important;
  }
}
}
.yellow-btn {
    background: #E1BF42;
    border-radius: 28px;
    display: inline-block;
    padding: 10px;
    color:#fff;
    min-width: 160px;
    text-align: center;
    margin: 15px 0;
}
.yellow-text {
  color: #E1BF42;
}
.sub-headingF {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
}
.m-0 {
  margin: 0 !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.col50 {
  flex: 0 0 50%;
  max-width: 50%;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.col-3 {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding-right: 15px;
  padding-left: 15px;
}
.text-right {
  text-align: right;
}


// Media Query

@media only screen and (max-width: 1024px) {
  .slick-slider .slick-next {
    right: 0 !important;
  }
  .slick-slider .slick-prev {
    left: 0 !important;
  }
}

@media only screen and (max-width: 768px) {
  .bayan-faculty .inner-faculty .slick-slide .name {
    padding: 10px;
  }
  .footer .top-footer ul li {
    margin: 2px !important;
  }
}


// .loader {
//   border: 16px solid #f3f3f3; /* Light grey */
//   border-top: 16px solid #3498db; /* Blue */
//   border-radius: 50%;
//   width: 120px;
//   height: 120px;
//   animation: spin 2s linear infinite;
// }

// @keyframes spin {
//   0% { transform: rotate(0deg); }
//   100% { transform: rotate(360deg); }
// }

.close {
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
  opacity: 0.8;
}
.close:hover {
  opacity: 1;
}
.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 14px;
  width: 2px;
  background-color: #2F5266;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
.btn {
  color: rgb(1, 56, 85);
  border: 1px solid rgb(1, 56, 85);
  display: inline-flex;
  padding: 6px;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  min-width: 150px;
  font-size: 13px;
  transition: all 0.3s linear 0s;
}
.btn-yellow {
  background-color: rgb(225, 191, 66);
  border-color: rgb(225, 191, 66);
  color: rgb(255, 255, 255);
}
.mt-3 {
  margin-top: 30px;
}

// Error Mesg


.input-err {
  border: 1px solid red !important;
}
.input-valid {
  border: 1px solid #00ff08 !important
}
.err-msg {
  color: red;
  white-space: pre-wrap;
  margin-top: 5px;
}


// action google facebook apple


.login-third-party {
  display: flex;
  align-items: center;
  justify-content: center;
  .login-round-btn {
      margin: 15px 5px;
      .facebook-button {
          background-color: transparent;
          border: 0;
          padding: 0 !important;
          img {
              width: 28px;
              height: 28px;
          }
      }
      .google-button {
          background-color: transparent !important;
          box-shadow: none !important;
          div {
              height: 28px;
              width: 28px;
              padding: 0 !important;
              margin: 0 !important;
              border-radius: 50% !important;
              display: flex;
              align-items: center;
              justify-content: center;
          }
          span {
              display: none;
          }
      }
  }
  .apple-login {
      img {
          width: 28px;
          height: 28px;
      }
  }
}


// Checkbox

.checkbox-w {
	display: block;
	position: relative;
	padding-left: 25px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 14px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
		&:checked {
			~ {
				.checkmark {
					&:after {
						display: block;
					}
				}
			}
		}
	}
	.checkmark {
		&:after {
      left: 5px;
      top: 0px;
      width: 5px;
      height: 10px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
		}
	}
}
.checkmark {
	position: absolute;
	top: 2px;
	left: 0;
	height: 16px;
  width: 16px;
  border: 1px solid #fff;
  border-radius: 2px;
	&:after {
		content: "";
		position: absolute;
		display: none;
	}
}

// checkbox
.underline {
  &:hover {
    text-decoration: underline;
  }
}
.mobile-only {
  display: none;
}


@media (max-width: 991px) {
  .mobile-hidden {
      display: none !important;
  }
  .col-sm-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
@media (max-width: 767px) {
  .bayan-banner .slick-dots {
    bottom: 20px  !important;
    top: inherit !important;
    left: 0;
    height: 30px;
    flex-direction: row !important;
    li {
      margin: 5px;
    }
  }
  .button-container {
    padding: 0 20px;
    a {
      width: 100% !important;
    }
  }
  .bayan-sub-banner {
    height: auto !important;
    min-height: 200px !important;
    padding: 25px 0;
  }
  .bayan-sub-banner .byan-sub-banner-holder {
    width: 100% !important;
    padding: 15px 15px !important;
    height: auto !important;
    max-height: inherit !important;
  }
  .bayan-faculty.blue-bg {
    .container .inner-block {
      max-width: 70%;
    }
  }
}
@media (max-width: 480px) {
  .mobile-only {
    display: block;
  }
  .see-all {
    text-align: center;
    padding: 10px 40px;
    display: block !important;
    a {
      padding: 12px 15px;
      color: #fff;
      background-color: #2E5266;
      width: 100%;
      border-radius: 30px;
      display: block;
    }
  }
  .bayan-classes.blue-bg {
    .see-all {
      padding-top: 0;
      a {
        color: #2E5266;
        background-color: #fff;
      }
    }
  }
  .bayan-faculty {
    background-color: #F2F2F2;
  }
  .bayan-faculty.blue-bg {
    padding-top: 0 !important;
    .headingF {
      color: #2E5266 !important;
    }
  }
  // .bayan-media {
  //   padding-top: 50px !important;
  //   background-color: #F2F2F2;
  //   .container {
  //     background-color: #fff;
  //     padding-top: 20px;
  //     padding-bottom: 20px;
  //   }
  // }
  .footer {
    background-color: #F2F2F2 !important;
  }
}
.pointer {
  cursor: pointer;
}
.on-mobile {
  display: none;
}
@media (max-width: 1024px) {
  .on-desktop {
    display: none;
  }
  .on-mobile {
    display: block;
  }
}
#react-joyride-step-1 > div,
#react-joyride-step-2 > div,
#react-joyride-step-3 > div,
#react-joyride-step-4 > div,
#react-joyride-step-0 > div {
  display: inline-block !important;
  filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 0px 3px) !important;
  max-width: 800px !important;
  opacity: 1 !important;
  position: fixed !important;
  transition: opacity 0.3s ease 0s !important;
  visibility: visible !important;
  z-index: 1000 !important;
  left: 10px !important;
  top: 17% !important;
  right: 10px !important;
  margin: auto !important;
  transform: inherit !important;
}

.logout-library {
  padding-top: 15px !important;
}
.nodata-block {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #2E5266;
  margin: 15px 0;

  label {
    font-weight: 600;
    margin: 15px 0;
  }
  .nodata-img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 240px;
    overflow: hidden;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .more-btn {
    background-color: #2E5266;
    color: #fff;
    border-radius: 30px;
    display: inline-block;
    padding: 10px 25px;
    margin-top: 15px;
    min-width: 221px;
    margin-bottom: 15px;
  }
}
.roundedimg {
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.userphoto {
  height: 100px;
  object-fit: cover;
}
.mt-0 {
  margin-top: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.cam {
  pointer-events: none;
}
.profile-action-bts {
  div {
    padding: 4px 0;
    display: flex;
    margin: 0 -5px;
    text-transform: capitalize;
    font-size: 14px;
    .edit-post,
    .delete-post {
      min-width: 18px;
    }
    img {
      margin-right: 5px !important;
    }
  }
}
.bayan-faculty .inner-faculty .slick-slide>div>div,
.slick-slide>div {
  display: flex !important;
  align-items: center;
  justify-content: center;
}


iframe#XCURMUKD {
    position: fixed;
    right: -71px;
    bottom: 186px;
    transform: rotate( -90deg);
    color: #fff;
    border-radius: 10px;
    font-size: 14px;
}
