@import './variables.scss';
@import './animation.scss';
@import './confirm.scss';

body.toastr-confirm-active {
  overflow: hidden;
}

.redux-toastr {
  *, *:before, *:after {
    box-sizing: border-box;
  }

  .top-left, .top-right, .top-center, .bottom-left, .bottom-right, .bottom-center {
    width: $toastr-width;
    position: fixed;
    z-index: 99999999;
    padding: 0 $defaultSpace;
  }

  .top-left, .top-right, .top-center {
    top: 0;
  }

  .top-right, .bottom-right {
    right: 0;
  }

  .bottom-left, .bottom-right, .bottom-center {
    bottom: 0;
  }

  .top-left, .bottom-left {
    left: 0;
  }

  .top-center, .bottom-center {
    left: 50%;
    margin-left: -($toastr-width / 2);
  }

  @media (max-width: 320px) {
    .top-left, .top-right, .top-center, .bottom-left, .bottom-right, .bottom-center {
      width: $toastr-width - 30;
    }

    .top-center, .bottom-center {
      $with: $toastr-width - 30;
      margin-left: -($with / 2);
    }
  }

  .toastr {
    background-color: #fcfcfc;
    width: 100%;
    min-height: 70px;
    overflow: hidden;
    margin: $defaultSpace 0;
    border-radius: 4px;
    position: relative;
    z-index: 2;
    color: #333;
    opacity: .94;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);

    &:hover:not(.rrt-message) {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
      opacity: 1;
    }

    .toastr-status {
      width: 100%;
      height: 5px;

      &.success {
        background-color: $successBgColor;
      }

      &.warning {
        background-color: $warningBgColor;
      }

      &.info {
        background-color: $infoBgColor;
      }

      &.error {
        background-color: $errorBgColor;
      }
    }

    .rrt-left-container, .rrt-right-container {
      float: left;
      text-align: center;
      overflow: hidden;
    }

    .rrt-left-container {
      width: 80px;
      top: 0;
      left: 0;
      position: absolute;
      bottom: 0;

      .rrt-holder {
        width: 70px;
        height: 70px;
        position: absolute;
        top: 50%;
        margin-top: -35px;
        left: 5px;
        line-height: 60px;
      }

      .toastr-icon {
        fill: white;
        vertical-align: middle;
        margin-top: 5px;
      }
    }

    .rrt-middle-container {
      width: 65%;
      margin-left: 80px;
      position: relative;
      float: left;
      font-family: Arial, Helvetica, sans-serif, sans-serif;
      font-size: 1em;
      text-align: left;
      padding: 10px 5px;

      .rrt-title {
        font-size: 1.1em;
        font-weight: bold;
        margin-bottom: 5px;
      }
    }

    .rrt-right-container {
      width: 10%;
    }

    .close-toastr {
      width: 10%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background-color: transparent;
      font-size: 22px;
      border: none;
      outline: none;
      opacity: 0.5;
      cursor: pointer;
      font-family: "Helvetica Neue", Helvetica, Arial sans-serif;
      color: #000;

      &:hover {
        opacity: 1;
      }

      &:focus {
        outline: none;
      }

      span {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &.rrt-info, &.rrt-success, &.rrt-warning, &.rrt-error {
      color: white;
    }

    &.rrt-info {
      background-color: $infoBgColor;

      .rrt-progressbar {
        background-color: darken($infoBgColor, 15%);
      }
    }

    &.rrt-success {
      background-color: $successBgColor;

      .rrt-progressbar {
        background-color: darken($successBgColor, 15%);
      }
    }

    &.rrt-warning {
      background-color: $warningBgColor;

      .rrt-progressbar {
        background-color: darken($warningBgColor, 15%);
      }
    }

    &.rrt-error {
      background-color: $errorBgColor;

      .rrt-progressbar {
        background-color: darken($errorBgColor, 15%);
      }
    }

    &.rrt-light {
      .rrt-progressbar {
        background-color: #ccc;
      }
      .toastr-icon {
        fill: #333 !important;
      }
    }

    &.rrt-message {
      opacity: 1;
      border: 1px solid #dbdbdb;

      .rrt-title {
        width: 90%;
        height: 50px;
        text-align: center;
        overflow: hidden;
        font-size: 1.2em;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 50px;
        padding: 0 20px;
      }

      .rrt-text {
        width: 100%;
        max-height: 400px;
        overflow: hidden;
        overflow-y: auto;
        border-top: 1px solid #f1f1f1;
        border-bottom: 1px solid #f1f1f1;
        background-color: white;
        padding: 15px;
        font-size: 1.1em;
        margin-bottom: 20px;

        img {
          display: block;
          margin: 10px auto;
          max-width: 100%;
        }
      }

      .close-toastr {
        height: 50px;
      }
    }

    .rrt-progress-container {
      height: 5px;
      margin: 0 -20px -20px -60px;
      position: absolute;
      bottom: 20px;
      width: 100%;

      .rrt-progressbar {
        -webkit-border-radius: 0 0 0 4px;
        -moz-border-radius: 0 0 0 4px;
        border-radius: 0 0 0 4px;
        height: 100%;
      }
    }
  }

  .toastr-attention {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
  }
}
